import {useHistory} from 'react-router';

import SearchInput from 'components/SearchInput';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import Typography from 'components/Typography';
import ListActionsWrapper from 'components/ListActionsWrapper';
import DataTable from 'components/DataTable';
import {Address, Customer, DataItem, ListResponse, Product} from 'types';
import styles from './Requests.module.scss';
import stat_time from '../DeliveryPlanner/icons/stat_time.svg';
import calendar from '../DeliveryPlanner/icons/calendar.svg';
import {DataTableSchemaItem} from 'types';
import useRequests from './useRequests';
import {useCallback, useEffect, useMemo, useState} from 'react';
import Pagination from 'components/Pagination';
import {useRouteMatch} from 'react-router-dom';
import DateTimePicker from 'components/DateTimePicker';
import Select from 'components/Select';
import {components} from 'react-select';
import {groupBy, isArray, map, reject, sortBy as sortLodash} from 'lodash';
import nyPostalCodeBoroughOptions from 'shared/ny-postal-code-borough-options';
import locationIcon from './location.svg';
import Button from 'components/Button';
import api from 'api';
import toast from 'react-hot-toast';
import Modal from 'components/Modal';
import {NewCustomer} from 'pages/Settings/Users/Users';
import {CreateAddress} from 'pages/UserForm/UserAddresses/UserAddresses';
import {NewProduct} from 'pages/Products/Products';
import {addressToString} from 'helpers/address';
import classNames from 'classnames';
import FilterIcon from './FilterIcon';

const SCHEMA: DataTableSchemaItem[] = [
  {dataKey: 'number', header: 'Request #', sortable: false},
  {dataKey: 'rx_no', header: 'Rx #', sortable: false},
  {dataKey: 'sync_time', header: 'Sync time', sortable: true},
  {dataKey: 'customer', header: 'Patient', sortable: true},
  {dataKey: 'status', header: 'Status', sortable: true},
  {dataKey: 'drug_name', header: 'Drug name', sortable: false},
  // {dataKey: 'refill_no', header: 'Refill #', sortable: false},
];
const MM_SCHEMA: DataTableSchemaItem[] = [
  {dataKey: 'rx_no', header: 'Rx #', sortable: true, index: 0},
  {dataKey: 'customer', header: 'Patient', sortable: true, index: 1},
  {dataKey: 'status', header: 'Status', sortable: true, index: 2},
  {dataKey: 'drug_name', header: 'Drug name', sortable: false, index: 3},
  {dataKey: 'amount_due', header: 'Amount', sortable: false, index: 4},
  {
    dataKey: 'number',
    header: 'Order #',
    sortable: true,
    align: 'center',
    index: 5,
  },
  {
    dataKey: 'sync_time',
    header: 'Sync time',
    sortable: true,
    align: 'center',
    index: 6,
  },
  {dataKey: 'bill_time', header: 'Bill Time', sortable: false, index: 7},
  {
    dataKey: 'preference_date',
    header: 'Preference date',
    sortable: false,
    index: 8,
  },
  // {dataKey: 'view', header: '', align: 'right'},
];

const locationOptionsGrouped = groupBy(nyPostalCodeBoroughOptions, 'label');
const sorted: {label: string; value: any}[] = map(
  locationOptionsGrouped,
  (obj, key) => ({
    label: key,
    value: map(obj, 'value'),
  })
);

const locationOptions = sortLodash(sorted, 'label');

type Props = {
  source: string;
};

function Requests({source}: Props) {
  const {params} = useRouteMatch<{page: string | undefined}>();
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const {
    data,
    totalPages,
    dailyCount,
    loading,
    sortBy,
    sortOrder,
    tabs,
    status,
    onSort,
    setStatus,
    keyWord,
    setKeyWord,
    MMtabs,
    onChangeLocation,
    location,
    setPreferenceTime,
    preferenceTime,
    setOnlyOTC,
    paid,
    setPaid,
    refetch,
    onChangeStatuses,
    statuses,
    visible,
    setVisible,
    setBillDate,
    onChangeType,
    types,
    pageSize,
    setPageSize,
    count,
    setLocation,
    setStatuses,
    setTypes,
    setDate,
  } = useRequests({page: currentPage - 1, source});
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [productIds, setProductIds] = useState<any>([]);
  const [userId, setUserId] = useState<any>(0);
  const [addressId, setAddressId] = useState<any>(0);
  const [clients, setClients] = useState<Customer[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [addressVisible, setAddressVisible] = useState(false);
  const [productVisible, setProductVisible] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);

  const [MMTable, setMMTable] = useState(
    MM_SCHEMA.map((item) => ({
      ...item,
      value: item.dataKey,
      label: item.header,
    }))
  );

  const getLocalTable = () => {
    let localTable = localStorage.getItem('tableData');
    if (localTable) {
      const data: any[] = MM_SCHEMA.filter((item) =>
        JSON.parse(localTable || '').find(
          (el: any) => el.dataKey === item.dataKey
        )
      );
      setMMTable(
        data.map((item) => ({...item, value: item.dataKey, label: item.header}))
      );
    }
  };

  useEffect(() => {
    getLocalTable();
  }, []);

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      if (source === 'app') {
        history.push(`/requests/${row.id}`);
      } else {
        history.push(`/orders/${row.id}`);
      }
    },
    [history, source]
  );

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  const onChangeTab = (val: any) => {
    if (val) {
      const newTabs = MMTable.some((item) => item.label === val.label)
        ? reject(MMTable, val)
        : [...MMTable, val];

      setMMTable(sortLodash(newTabs, 'index'));

      localStorage.setItem(
        'tableData',
        JSON.stringify(sortLodash(newTabs, 'index'))
      );
    }
  };

  const getProducts = async (page: number, keyWord: string) => {
    try {
      const {data} = await api.get<ListResponse<Product>>(`/products/`, {
        params: {
          limit: 50,
          offset: (page - 1) * 50,
          search: keyWord,
        },
      });
      setProducts((pr) =>
        page === 1 ? data.results : pr.concat(data.results)
      );
    } catch (error) {}
  };

  const getClients = async (page: number, keyWord: string) => {
    try {
      const {data} = await api.get<ListResponse<Customer>>(`/customers/`, {
        params: {
          limit: 50,
          offset: (page - 1) * 50,
          search: keyWord,
        },
      });
      setClients((pr) => (page === 1 ? data.results : pr.concat(data.results)));
    } catch (error) {}
  };

  const getAddress = async (id: number) => {
    setAddresses([]);
    try {
      const {data} = await api.get<Customer>(`/customers/${id}/`);
      setAddresses(data.addresses || []);
    } catch (error) {}
  };

  const onSubmit = () => {
    setSubmitting(true);
    api
      .post(source === 'app' ? 'requests/create/' : 'requests/create-order/', {
        customer_id: userId,
        address_id: addressId,
        drugs: productIds,
      })
      .then(({data}) => {
        refetch();
        setUserId(0);
        setAddressId(0);
        setProductIds([]);
        handleNavigateRequest(data);
        setVisible(false);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const [filter, setFilter] = useState<any>({
    location: [],
    onlyOTC: sessionStorage.getItem('onlyOTC')
      ? JSON.parse(sessionStorage.getItem('onlyOTC') || '')
      : undefined,
    paid: sessionStorage.getItem('paid')
      ? JSON.parse(sessionStorage.getItem('paid') || '')
      : undefined,
    statuses: sessionStorage.getItem(`request_statuses`)
      ? JSON.parse(sessionStorage.getItem(`request_statuses`) as any)
      : [],
    billDate: sessionStorage.getItem('bill_date')
      ? new Date(JSON.parse(sessionStorage.getItem('bill_date') || ''))
      : undefined,
    date: sessionStorage.getItem('date')
      ? new Date(JSON.parse(sessionStorage.getItem('date') || ''))
      : undefined,
    preferenceTime: sessionStorage.getItem('preferenceTime')
      ? JSON.parse(sessionStorage.getItem('preferenceTime') || '')
      : undefined,
  });

  const filtered = useMemo(() => {
    return (
      (filter.location.length > 0 ||
        filter.statuses.length > 0 ||
        !!filter.billDate ||
        !!filter.date ||
        !!filter.preferenceTime ||
        filter.onlyOTC !== undefined ||
        filter.paid !== undefined) &&
      !filterVisible
    );
  }, [filter, filterVisible]);

  const resetFilter = () => {
    setFilterVisible(false);
    const data = {
      location: [],
      onlyOTC: undefined,
      paid: undefined,
      statuses: [],
      billDate: undefined,
      date: undefined,
      preferenceTime: undefined,
    };
    setFilter(data);
    filterHandler(data);
  };

  const filterHandler = (filter: any) => {
    setLocation(filter.location);
    setOnlyOTC(filter.onlyOTC);
    setPaid(filter.paid);
    setStatuses(filter.statuses);
    setTypes(filter.types);
    setBillDate(filter.billDate);
    setDate(filter.date);
    setPreferenceTime(filter.preferenceTime);
  };

  const applyFilter = () => {
    setFilterVisible(false);
    filterHandler(filter);
    history.push(
      source === 'micromerchant' ? '/orders/page/1' : '/requests/page/1'
    );
  };

  return (
    <>
      <div className={styles.top_header}>
        <SearchInput
          className={styles.input}
          value={keyWord}
          onChange={(val) => {
            setKeyWord(val.target.value);
            setCurrentPage(1);
            if (params.page !== '1') {
              if (source === 'app') {
                history.push('/requests/page/1');
              } else {
                history.push('/orders/page/1');
              }
            }
            sessionStorage.setItem(`searchKey_${source}`, val.target.value);
          }}
          placeholder="Search"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 6,
          }}
        >
          {source !== 'app' && (
            <Button
              onClick={() => {
                setVisible(true);
                getClients(1, '');
                getProducts(1, '');
              }}
              color="blue"
            >
              {source === 'app' ? 'Add request' : 'Add Order'}
            </Button>
          )}
          <Typography variant="h4">
            {source === 'app' ? 'Today’s App Requests' : 'Today’s Orders :'}{' '}
            {dailyCount}
          </Typography>
        </div>
      </div>
      <ListWrapper>
        <ListTabs
          onChange={setStatus}
          isSupportPage={false}
          activeTabValue={status}
          tabs={source === 'micromerchant' ? MMtabs : tabs}
        />
        <ListActionsWrapper>
          <div className={styles.row}>
            {source === 'micromerchant' && (
              <div
                className={styles.filterBtn}
                style={{
                  borderColor: filtered ? '#0072b3' : '#E0E0E0',
                }}
                onClick={() => setFilterVisible(true)}
              >
                <FilterIcon isFilled={filtered} />
                <div
                  style={{
                    color: '#28303F',
                  }}
                >
                  {filtered ? 'Filtered' : 'Filter'}
                </div>
              </div>
            )}
            {source === 'app' && (
              <Select
                className={'DeliveryPlanner__Filter'}
                placeholder="Location"
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !location ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  Control: ({children, ...props}) => {
                    return (
                      <components.Control {...props}>
                        <img
                          className={styles.locationIcon}
                          src={locationIcon}
                          alt="location_icon"
                        />
                        {children}
                      </components.Control>
                    );
                  },
                  SingleValue: ({children, ...props}) => {
                    return (
                      <components.SingleValue {...props}>
                        {isArray(props.selectProps.value)
                          ? props.selectProps.value
                              .map((item: {label: string}) => item.label)
                              .join(', ')
                          : ''}
                      </components.SingleValue>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                onChange={(data) => {
                  history.push('/requests/page/1');
                  onChangeLocation(data);
                }}
                isClearable
                options={[
                  {
                    label: 'Locations',
                    options: locationOptions,
                  },
                ]}
                value={location}
              />
            )}
            {source === 'app' && status === 'completed' && (
              <Select
                className={classNames(
                  'DeliveryPlanner__Filter',
                  styles.selectCon,
                  styles.selectStatus
                )}
                placeholder="Status"
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !(statuses.length > 0) ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  SingleValue: ({children, ...props}) => {
                    return (
                      <components.SingleValue {...props}>
                        {isArray(props.selectProps.value)
                          ? props.selectProps.value
                              .map((item: {label: string}) => item.label)
                              .join(', ')
                          : ''}
                      </components.SingleValue>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                onChange={(data) => {
                  history.push('/requests/page/1');
                  onChangeStatuses(data);
                }}
                isClearable
                options={[
                  {
                    label: 'Statuses',
                    options: [
                      {
                        label: 'Cancel script',
                        value: 'cancel_script',
                      },
                      {
                        label: 'No script available',
                        value: 'no_script_available',
                      },
                      {
                        label: 'Completed',
                        value: 'completed',
                      },
                    ],
                  },
                ]}
                value={statuses}
              />
            )}
            {source === 'app' && (
              <Select
                className={classNames(styles.selectCon, styles.selectStatus)}
                placeholder="Type"
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !types ? <components.DropdownIndicator {...props} /> : null,
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                onChange={(data) => {
                  history.push('/requests/page/1');
                  onChangeType(data);
                }}
                options={[
                  {
                    label: 'Call Doctor',
                    value: 'call_doctor',
                  },
                  {
                    label: 'Meds Kit',
                    value: 'meds_kit',
                  },
                  {
                    label: 'App Refill ',
                    value: 'app_refill',
                  },
                ]}
                value={types}
              />
            )}
            {/* {source === 'micromerchant' && (
              <Select
                className={styles.selectCon}
                isSearchable={false}
                onChange={(val: any) => {
                  history.push('/orders/page/1');
                  setOnlyOTC(val);
                }}
                options={[
                  {label: 'OTC', value: 'true'},
                  {label: 'Rx', value: 'false'},
                ]}
                value={onlyOTC}
                placeholder={
                  <span className="select-placeholder-text">OTC/Rx</span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !onlyOTC ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  IndicatorSeparator: () => null,
                }}
              />
            )}
            {source === 'micromerchant' && (
              <Select
                className={styles.selectCon}
                isSearchable={false}
                onChange={(val: any) => {
                  history.push('/orders/page/1');
                  setPaid(val);
                }}
                options={[
                  {label: 'Paid', value: 'true'},
                  {label: 'Unpaid', value: 'false'},
                ]}
                value={paid}
                placeholder={
                  <span className="select-placeholder-text">Paid/Unpaid</span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !paid ? <components.DropdownIndicator {...props} /> : null,
                  IndicatorSeparator: () => null,
                }}
              />
            )} */}
          </div>

          {source === 'micromerchant' && (
            <div className={styles.row}>
              {/* <DateTimePicker
                icon={calendar}
                showTimeSelect={false}
                dateFormat="MM/dd/yy E"
                value={billdate}
                placeholder="Bill date"
                className={styles.customInput}
                onChange={(data) => {
                  history.push('/orders/page/1');
                  setBillDate(data);
                }}
              />
              <div style={{width: 15}} />

              <DateTimePicker
                icon={calendar}
                showTimeSelect={false}
                dateFormat="MM/dd/yy E"
                value={date}
                placeholder="Pr. date"
                className={styles.customInput}
                onChange={(data) => {
                  history.push('/orders/page/1');
                  onChangeData(data);
                }}
              />

              <Select
                className={styles.selectCon}
                isSearchable={false}
                onChange={(val: any) => {
                  history.push('/orders/page/1');
                  setPreferenceTime(val);
                }}
                options={[
                  {label: 'Morning', value: 'morning'},
                  {label: 'Afternoon', value: 'afternoon'},
                  {label: 'Evening', value: 'evening'},
                ]}
                value={preferenceTime}
                placeholder={
                  <span className="select-placeholder-text">Pr. time</span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !preferenceTime ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  Control: ({children, ...props}) => (
                    <components.Control {...props} className="select-row">
                      <img className={'timer'} src={stat_time} alt="timer" />
                      {children}
                    </components.Control>
                  ),

                  IndicatorSeparator: () => null,
                }}
              /> */}

              <Select
                className={'Table_Select'}
                styles={{
                  container: (base) => ({
                    ...base,
                    marginLeft: 0,
                  }),
                }}
                isSearchable={false}
                onChange={onChangeTab}
                options={[
                  {
                    label: 'Table column',
                    options: MM_SCHEMA.map((item) => ({
                      ...item,
                      value: item.dataKey,
                      label: item.header,
                    })),
                  },
                ]}
                value={MMTable}
                placeholder={
                  <span className="select-placeholder-text">Display</span>
                }
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !preferenceTime ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  SingleValue: ({children, ...props}) => {
                    return (
                      <components.SingleValue {...props}>
                        Display
                      </components.SingleValue>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                closeMenuOnSelect={false}
              />
            </div>
          )}
        </ListActionsWrapper>
        <DataTable
          schema={source === 'app' ? SCHEMA : MMTable}
          data={data}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onClick={handleNavigateRequest}
          onSort={onSort}
          loading={loading}
        />
        {!loading && (
          <Pagination
            count={count}
            basePath={source === 'app' ? '/requests' : '/orders'}
            page={currentPage}
            total={totalPages}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
          />
        )}
      </ListWrapper>
      <Modal
        isOpen={filterVisible}
        className={styles.filterModal}
        onClose={() => setFilterVisible(false)}
        onRequestClose={() => setFilterVisible(false)}
      >
        <Typography variant="h2">Filter</Typography>
        <div className={styles.filterContainer}>
          <Select
            className={'DeliveryPlanner__Filter'}
            placeholder="Location"
            menuPosition="fixed"
            components={{
              Input: () => null,
              DropdownIndicator: (props) =>
                !location ? <components.DropdownIndicator {...props} /> : null,
              Control: ({children, ...props}) => {
                return (
                  <components.Control {...props}>
                    <img
                      className={styles.locationIcon}
                      src={locationIcon}
                      alt="location_icon"
                    />
                    {children}
                  </components.Control>
                );
              },
              SingleValue: ({children, ...props}) => {
                return (
                  <components.SingleValue {...props}>
                    {isArray(props.selectProps.value)
                      ? props.selectProps.value
                          .map((item: {label: string}) => item.label)
                          .join(', ')
                      : ''}
                  </components.SingleValue>
                );
              },
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
            onChange={(data) => {
              let location: any = [];
              if (data) {
                location = filter.location.some(
                  (item: any) => item.label === data.label
                )
                  ? reject(filter.location, data)
                  : [...filter.location, data];
              }
              setFilter((prev: any) => ({
                ...prev,
                location,
              }));
            }}
            isClearable
            options={[
              {
                label: 'Locations',
                options: locationOptions,
              },
            ]}
            value={filter.location}
          />

          <div className={styles.row}>
            <Select
              className={styles.selectCon}
              isSearchable={false}
              menuPosition="fixed"
              onChange={(val: any) => {
                setFilter((prev: any) => ({
                  ...prev,
                  onlyOTC: val || undefined,
                }));
              }}
              options={[
                {label: 'Rx', value: 'false'},
                {label: 'Rx Refill ', value: 'mm_refill'},
                {label: 'OTC', value: 'true'},
              ]}
              value={filter.onlyOTC}
              placeholder={
                <span className="select-placeholder-text">Type</span>
              }
              isClearable
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !filter.onlyOTC ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                IndicatorSeparator: () => null,
              }}
            />

            <Select
              className={styles.selectCon}
              isSearchable={false}
              menuPosition="fixed"
              onChange={(val: any) => {
                setFilter((prev: any) => ({
                  ...prev,
                  paid: val || undefined,
                }));
              }}
              options={[
                {label: 'Paid', value: 'true'},
                {label: 'Unpaid', value: 'false'},
              ]}
              value={filter.paid}
              placeholder={
                <span className="select-placeholder-text">Paid/Unpaid</span>
              }
              isClearable
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !paid ? <components.DropdownIndicator {...props} /> : null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className={styles.row}>
            <DateTimePicker
              icon={calendar}
              showTimeSelect={false}
              dateFormat="MM/dd/yy E"
              value={filter.billDate}
              placeholder="Bill date"
              className={styles.customInput}
              onChange={(data) => {
                setFilter((prev: any) => ({
                  ...prev,
                  billDate: data,
                }));
              }}
            />

            <DateTimePicker
              icon={calendar}
              showTimeSelect={false}
              dateFormat="MM/dd/yy E"
              value={filter.date}
              placeholder="Pr. date"
              className={styles.customInput}
              onChange={(data) => {
                setFilter((prev: any) => ({
                  ...prev,
                  date: data,
                }));
              }}
            />

            <Select
              className={styles.selectCon}
              isSearchable={false}
              menuPosition="fixed"
              onChange={(val: any) => {
                setFilter((prev: any) => ({
                  ...prev,
                  preferenceTime: val,
                }));
              }}
              options={[
                {label: 'Morning', value: 'morning'},
                {label: 'Afternoon', value: 'afternoon'},
                {label: 'Evening', value: 'evening'},
              ]}
              value={filter.preferenceTime}
              placeholder={
                <span className="select-placeholder-text">Pr. time</span>
              }
              isClearable
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !preferenceTime ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                Control: ({children, ...props}) => (
                  <components.Control {...props} className="select-row">
                    <img className={'timer'} src={stat_time} alt="timer" />
                    {children}
                  </components.Control>
                ),

                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
        <div className={styles.filterBtnContainer}>
          <Button onClick={resetFilter} color="gray">
            Reset
          </Button>
          <Button onClick={applyFilter} color="blue">
            Apply
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={visible}
        onClose={() => setVisible(false)}
        className={styles.list_wrapper}
        onRequestClose={() => setVisible(false)}
      >
        <Typography variant="h2">
          Add new {source === 'app' ? 'request' : 'order'}
        </Typography>
        <div
          style={{display: 'flex', alignItems: 'center', gap: 10}}
          className={styles.label}
        >
          <div>Patient </div>
          <PlusComponent onClick={() => setModalOpen(true)} />
        </div>
        <Select
          // label="Customer:"
          onInputChange={(text, action) => {
            if (action.action === 'input-change') {
              getClients(1, text);
            }
          }}
          onMenuScrollToBottom={(event) => {
            getClients(Math.floor(clients.length / 50) + 1, '');
          }}
          menuPosition="fixed"
          placeholder="Click to select"
          onChange={(item) => {
            setUserId(item?.value);
            getAddress(Number(item?.value));
          }}
          value={
            clients.find((item) => item.id === userId)
              ? {
                  value:
                    clients.find((item) => item.id === userId)?.user?.id || '',
                  label:
                    clients.find((item) => item.id === userId)?.user
                      ?.first_name +
                    ' ' +
                    clients.find((item) => item.id === userId)?.user?.last_name,
                }
              : undefined
          }
          options={clients.map((item) => ({
            value: item.id,
            label: item.user.first_name + ' ' + item.user.last_name,
          }))}
        />
        {userId !== 0 && (
          <>
            <div
              style={{display: 'flex', alignItems: 'center', gap: 10}}
              className={styles.label}
            >
              <div>Address</div>
              <PlusComponent onClick={() => setAddressVisible(true)} />
            </div>
            <Select
              placeholder="Click to select"
              menuPosition="fixed"
              onChange={(item) => {
                setAddressId(item?.value);
              }}
              value={
                addresses.find((item) => item.id === addressId)
                  ? {
                      value:
                        addresses.find((item) => item.id === addressId)?.id ||
                        '',
                      label: addressToString(
                        addresses.find(
                          (item) => item.id === addressId
                        ) as Address
                      ),
                    }
                  : undefined
              }
              options={addresses.map((item) => ({
                value: item.id,
                label:
                  item.address_line_1 +
                  ',' +
                  item.city +
                  ',' +
                  item.state +
                  ',' +
                  item.zipcode,
              }))}
            />
          </>
        )}
        <div
          style={{display: 'flex', alignItems: 'center', gap: 10}}
          className={styles.label}
        >
          <div>Product</div>
          <PlusComponent onClick={() => setProductVisible(true)} />
        </div>
        <Select
          onInputChange={(text, action) => {
            if (action.action === 'input-change') {
              getProducts(1, text);
            }
          }}
          onMenuScrollToBottom={(event) => {
            getProducts(Math.floor(products.length / 50) + 1, '');
          }}
          placeholder="Click to select"
          menuPosition="fixed"
          onChange={(item) => {
            if (isArray(item)) {
              setProductIds(item.map((item) => item.value));
            } else {
              setProductIds([item?.value]);
            }
          }}
          value={
            productIds.length > 0
              ? {
                  value:
                    products.find((item) => item.id === productIds[0])?.id ||
                    '',
                  label: products.find((item) => item.id === productIds[0])
                    ?.name,
                }
              : undefined
          }
          //@ts-ignore
          isSearchable={true}
          //@ts-ignore
          isMulti={source === 'app'}
          options={products.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />

        <Button
          loading={submitting}
          disabled={!addressId || !userId || !productIds.length}
          className={styles.addBtn}
          onClick={onSubmit}
          color="blue"
        >
          {source === 'app' ? 'Add request' : 'Add Order'}
        </Button>
        <NewCustomer
          onSuccess={(data) => {
            setClients((pr) => [data, ...pr]);
            setUserId(data.id);
          }}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />

        <CreateAddress
          customer_id={userId}
          handleClose={() => setAddressVisible(false)}
          onSuccess={(data) => {
            setAddresses((pr) => [data, ...pr]);
            setAddressId(data.id);
          }}
          isOpen={addressVisible}
        />
        <NewProduct
          visible={productVisible}
          onClose={() => setProductVisible(false)}
          onSuccess={(data) => {
            setProducts((pr) => [data, ...pr]);
            setProductIds([data.id]);
          }}
        />
      </Modal>
    </>
  );
}

export const PlusComponent = ({onClick}: {onClick: () => void}) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: 22,
        height: 22,
        display: 'flex',
        backgroundColor: '#27ae60',
        color: '#fff',
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      +
    </div>
  );
};

export default Requests;
