import {format, parse} from 'date-fns';
import Typography from 'components/Typography';
import Card from 'components/Card';
import CardContent from 'components/CardContent';

import {Customer} from 'types';
import {getFullName} from 'helpers/user';

import IdentificationCard from './icons/IdentificationCard.svg';

import styles from '../RequestDetails.module.scss';
import {Link} from 'react-router-dom';
import {Address} from './DeliveryAdressType';
import ArrowBottomIcon from './icons/ArrowBottomIcon';
import {get} from 'lodash';
import useAuth from 'hooks/useAuth';

interface Props {
  pharmacy: {
    name: string;
    phone: string;
    address: string;
  };
  isOrder: boolean;
  customer: Customer;
  deliveryData: Address;
  modalHandler: () => void;
  allergies: string;
}

function CustomerDetails({
  pharmacy,
  customer,
  deliveryData,
  isOrder,
  modalHandler,
  allergies,
}: Props) {
  const {currentUser} = useAuth();
  const isPrescriber = get(currentUser, 'role') === 'prescriber';

  return (
    <Card>
      {!isOrder && (
        <CardContent dark>
          <div className={styles.info}>
            <Typography component="p" variant="label">
              Pharmacy
            </Typography>
            <Typography component="p" variant="h2">
              {pharmacy?.name || '-'}
            </Typography>
          </div>
          <div className={styles.info}>
            <Typography component="p" variant="label">
              Phone Number
            </Typography>
            <Typography component="p" variant="h3">
              {pharmacy?.phone
                ? '(' +
                  pharmacy.phone.replace(/\D/g, '').substring(0, 3) +
                  ') ' +
                  pharmacy.phone.replace(/\D/g, '').substring(3, 6) +
                  '-' +
                  pharmacy.phone.replace(/\D/g, '').substring(6, 10)
                : '-'}
            </Typography>
          </div>
          <div className={styles.info}>
            <Typography component="p" variant="label">
              Address
            </Typography>
            <Typography component="p" variant="h3">
              {pharmacy?.address || '-'}
            </Typography>
          </div>
        </CardContent>
      )}
      <CardContent>
        <div className={styles.info}>
          <div className={styles.row}>
            <Typography component="p" variant="label">
              Patient
            </Typography>
            {!!get(customer, 'patient_no', null) && (
              <Typography className={styles.text} component="p" variant="label">
                #{get(customer, 'patient_no', null)}
              </Typography>
            )}
          </div>
          <Typography className={styles.fullname} component="p" variant="h2">
            {getFullName(customer.user)}
          </Typography>
          <Link to={`/users/${customer.id}/edit`}>
            <img
              className={styles.identification_card}
              alt="Identification card"
              src={IdentificationCard}
            />
          </Link>
        </div>
        <div className={styles.horizont}>
          <div>
            <Typography component="p" variant="label">
              Phone Number
            </Typography>
            <Typography component="p" variant="h3">
              {customer.user.phone
                ? customer.user.phone.includes('+1')
                  ? '(' +
                    customer.user.phone.replace(/\D/g, '').substring(1, 4) +
                    ') ' +
                    customer.user.phone.replace(/\D/g, '').substring(4, 7) +
                    '-' +
                    customer.user.phone.replace(/\D/g, '').substring(7, 11)
                  : '(' +
                    customer.user.phone.replace(/\D/g, '').substring(0, 3) +
                    ') ' +
                    customer.user.phone.replace(/\D/g, '').substring(3, 6) +
                    '-' +
                    customer.user.phone.replace(/\D/g, '').substring(6, 10)
                : '-'}
            </Typography>
          </div>
          <div className={styles.info}>
            <Typography component="p" variant="label">
              Date of birth
            </Typography>
            <Typography className={styles.dobText} component="p" variant="h3">
              {customer.birthday &&
                format(
                  parse(customer.birthday, 'yyyy-MM-dd', new Date()),
                  'MMMM, dd, yyyy'
                )}
            </Typography>
          </div>
        </div>

        <div className={styles.info}>
          <Typography component="p" variant="label">
            Address
          </Typography>
          <button
            disabled={isPrescriber || !deliveryData}
            onClick={modalHandler}
            className={styles.address_button}
          >
            {deliveryData ? (
              <Typography
                style={{fontSize: 24}}
                className={styles.delivery_address}
                component="p"
                variant="h3"
              >
                {get(deliveryData, 'address_line_1', '')},{' '}
                {get(deliveryData, 'city', '')},{' '}
                {get(deliveryData, 'state', '')},{' '}
                {get(deliveryData, 'zipcode', '')}
                {get(deliveryData, 'unit_address', '')
                  ? `, ${get(deliveryData, 'unit_address', '')}`
                  : ''}
              </Typography>
            ) : (
              <Typography
                style={{fontSize: 24}}
                className={styles.delivery_address}
                component="p"
                variant="h3"
              >
                -
              </Typography>
            )}
            {!get(deliveryData, 'is_valid', true) && (
              <span className={styles.wrongAddress}>issue</span>
            )}
            {!isPrescriber && <ArrowBottomIcon />}
          </button>
        </div>
        {/* {!!allergies && ( */}
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Allergies
          </Typography>
          <Typography style={{marginTop: 10, fontSize: 24}}>
            {allergies || '-'}
          </Typography>
        </div>
        {/* )} */}
      </CardContent>
    </Card>
  );
}

export default CustomerDetails;
