import api from 'api';
import ViewDetails from 'components/ViewDetails';
import {format} from 'date-fns';
import {get} from 'lodash';
import {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {QueryFunctionContext, useQuery} from 'react-query';
import ReactSwitch from 'react-switch';
import {BlogResponse, ListResponse} from 'types';

async function getBlogs({queryKey}: QueryFunctionContext<string[]>) {
  const [, page, search, status, pageSize] = queryKey;
  const {data} = await api.get<ListResponse<BlogResponse>>('/support/blogs/', {
    params: {
      page,
      offset: +page * +pageSize,
      limit: +pageSize,
      search,
      status,
      content_type: 'blog',
    },
  });
  return data;
}

const transformData = (data: BlogResponse[]) => {
  return data.map((item) => ({
    ...item,
    image: item.image ? (
      <img
        style={{width: 70, height: 40, objectFit: 'contain'}}
        alt={item.title}
        src={item.image.medium}
      />
    ) : null,
    active: <BlogStatus id={item.slug} active={item.is_active} />,
    created_at: format(new Date(item.created_at), 'MM/dd/yyyy hh:mm a'),
    view: <ViewDetails to={`/website/blogs/${item.slug}`} />,
  }));
};

function useBlogs() {
  const [currentPage, setCurrentPage] = useState(parseInt('1'));
  const [pageSize, setPageSize] = useState(40);

  const {data, isLoading} = useQuery({
    queryKey: ['blogs', String(currentPage - 1), String(pageSize)],
    queryFn: getBlogs,
  });

  return {
    data: data?.results ? transformData(data.results) : [],
    isLoading,
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    count: data?.count || 0,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  };
}

export const BlogStatus = ({
  active,
  id,
  onChangeStatus,
}: {
  active: boolean;
  id: string;
  onChangeStatus?: (value: boolean) => void;
}) => {
  const [checked, setChecked] = useState(active);

  useEffect(() => {
    setChecked(active);
  }, [active]);

  const onChange = () => {
    setChecked((pr) => !pr);
    if (onChangeStatus) {
      onChangeStatus(!checked);
      return;
    }
    api
      .patch(`/support/blogs/${id}/`, {
        is_active: !checked,
      })
      .then(() => {})
      .catch((err) => {
        toast.error(
          get(err, 'response.data.message', '') || 'Failed to update status'
        );
        setChecked((pr) => !pr);
      });
  };

  return (
    <div>
      <label>
        <ReactSwitch
          onColor="#0072b3"
          onChange={onChange}
          handleDiameter={17}
          height={20}
          width={40}
          checked={checked}
        />
      </label>
    </div>
  );
};

export default useBlogs;
