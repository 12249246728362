import {format, parseISO} from 'date-fns';
import Typography from 'components/Typography';
import Back from 'components/Back';

import styles from '../RequestDetails.module.scss';
import Button from 'components/Button';

const returnType = (type: string) => {
  if (type === 'patient_app') {
    return 'Patient App';
  }
  if (type === 'pos') {
    return 'POS';
  }
  if (type === 'dashboard') {
    return 'Dashboard';
  }
  if (type === 'driver_app_cod') {
    return 'Driver App COD';
  }
};

interface Props {
  id: string;
  type: string;
  amount: string;
  created_at: string;
  service_name: string;
}

function Header({id, type, amount, created_at, service_name}: Props) {
  const datetime = parseISO(created_at);
  return (
    <div className={styles.header}>
      <div>
        <div className={styles.title_wrapper}>
          <Back />
          <div>
            <div className={styles.title_wrapper}>
              <Typography className={styles.title} variant="h2">
                Transaction #{id}{' '}
              </Typography>
              {service_name === 'CreditReturn' && (
                <Button
                  style={{
                    width: 60,
                    height: 22,
                    fontSize: 11,
                  }}
                  size="sm"
                  color="danger"
                >
                  refunded
                </Button>
              )}
            </div>
            <Typography className={styles.subtitle} variant="h5">
              {format(datetime, 'MMMM d, yyyy')} from {returnType(type)}
            </Typography>
          </div>
        </div>
      </div>
      <Typography className={styles.title} variant="h2">
        Total amount: ${amount}
      </Typography>
    </div>
  );
}

export default Header;
