import {useEffect, useMemo, useState} from 'react';
import {format} from 'date-fns';
import {QueryFunctionContext, useQuery} from 'react-query';

import Typography from 'components/Typography';
import StatusBadge from 'components/StatusBadge';
import ViewDetails from 'components/ViewDetails';

import {ListResponse, Request} from 'types';
import useSort from 'hooks/useSort';
import api from 'api';
import {
  mapStatusColor,
  mapStatusText,
  mapMMStatusText,
  mapMMSubStatusColor,
} from 'constants/mappings';
import {get, reject} from 'lodash';
import {FilterOption} from 'pages/DeliveryPlanner/components/Filter/Filter';

async function getRequests({queryKey}: QueryFunctionContext<any[]>) {
  const [
    ,
    page,
    order,
    status,
    userId,
    keyWord,
    source,
    date,
    billdate,
    location,
    preference_time,
    onlyOTC,
    paid,
    statuses,
    type,
    pageSize,
  ] = queryKey;

  const {data} = await api.get<ListResponse<Request>>('/requests', {
    params: {
      order: order,
      status: status || undefined,
      request_type:
        onlyOTC?.value === 'mm_refill'
          ? 'mm_refill'
          : source === 'app' && type
          ? type.value
          : undefined,
      user_id: userId || undefined,
      offset: +page * pageSize,
      search: keyWord || undefined,
      limit: pageSize,
      source: source,
      preference_date:
        source !== 'app' && date ? format(date, 'yyyy-MM-dd') : undefined,
      bill_time:
        source !== 'app' && billdate
          ? format(billdate, 'yyyy-MM-dd')
          : undefined,
      zip_code:
        location.length > 0
          ? location.reduce((pr: [], curr: any) => [...pr, curr.label], [])
          : undefined,
      closed_statuses:
        statuses.length > 0 && status === 'completed'
          ? statuses.reduce((pr: [], curr: any) => [...pr, curr.value], [])
          : undefined,
      preference_time:
        source !== 'app' && preference_time ? preference_time.value : undefined,
      only_otc:
        source !== 'app' && onlyOTC && onlyOTC !== 'mm_refill'
          ? onlyOTC.value === 'true'
          : undefined,
      is_paid: source !== 'app' && paid ? paid.value === 'true' : undefined,
    },
  });

  return data;
}

function transformData(data: Request[], source: string) {
  return data.map((item) => ({
    ...item,
    rx_no:
      source === 'micromerchant'
        ? `${item.rx_no || ''}${item.refill_no ? ` (${item.refill_no})` : ''}
          `
        : item.rx_no,
    sync_time: get(item, 'sync_date', '') + '\n' + item.sync_time,
    bill_time:
      (get(item, 'bill_date', '') || '') + '\n' + (item.bill_time || ''),
    amount_due: `$${item.amount_due?.toFixed(2)}`,
    number: <Typography variant="strong">#M{item.id}</Typography>,
    drug_name:
      source === 'micromerchant'
        ? item.drug_name ?? ''
        : item.drug_name
        ? `${item.drug_name}...`
        : '',
    preference_date:
      item.preference_date || item.preference_time
        ? `${item.preference_date || '--/--/--'} / ${
            item.preference_time || '-'
          }`
        : '',

    status:
      source === 'micromerchant' ? (
        // @ts-ignore
        <StatusBadge color={mapMMSubStatusColor[item.status]}>
          {/*// @ts-ignore*/}
          {mapMMStatusText[item.status]}
        </StatusBadge>
      ) : (
        <StatusBadge
          color={
            item.status === 'call_doctor' && item.is_resolved
              ? 'green'
              : mapStatusColor[item.status]
          }
        >
          {/*@ts-ignore*/}
          {mapStatusText[item.status]}
        </StatusBadge>
      ),
    view: <ViewDetails to={`/requests/${item.id}`} />,
  }));
}

type RequestFilter = {
  userId?: string;
  source?: string;
  page?: number;
};

function useRequests(filter: RequestFilter) {
  const {sortBy, sortOrder, order, onSort} = useSort('id', 'desc');
  const [source] = useState(filter.source || '');
  const [pageSize, setPageSize] = useState(40);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(
    sessionStorage.getItem('state') === 'all'
      ? ''
      : sessionStorage.getItem(
          source === 'app' ? 'requests_state' : 'orders_state'
        ) || ''
  );
  const [key, setKeyWord] = useState(
    sessionStorage.getItem(`searchKey_${source}`) || ''
  );
  const [keyWord, setApiKeyWord] = useState(
    sessionStorage.getItem(`searchKey_${source}`) || ''
  );
  const [location, setLocation] = useState<FilterOption[]>([]);
  const [statuses, setStatuses] = useState<FilterOption[]>(
    sessionStorage.getItem(`request_statuses`)
      ? JSON.parse(sessionStorage.getItem(`request_statuses`) as any)
      : []
  );
  const [types, setTypes] = useState<FilterOption>(
    sessionStorage.getItem(`request_types`)
      ? JSON.parse(sessionStorage.getItem(`request_types`) as any)
      : undefined
  );

  const initialDate = sessionStorage.getItem('date')
    ? new Date(JSON.parse(sessionStorage.getItem('date') || ''))
    : undefined;

  const initialBillDate = sessionStorage.getItem('bill_date')
    ? new Date(JSON.parse(sessionStorage.getItem('bill_date') || ''))
    : undefined;

  const [date, setDate] = useState(initialDate);
  const [billdate, setBillDate] = useState(initialBillDate);

  const initialTime = sessionStorage.getItem('preferenceTime')
    ? JSON.parse(sessionStorage.getItem('preferenceTime') || '')
    : undefined;

  const [preferenceTime, setPreferenceTime] = useState<
    undefined | {value: string; label: string}
  >(initialTime);

  const initialOTC = sessionStorage.getItem('onlyOTC')
    ? JSON.parse(sessionStorage.getItem('onlyOTC') || '')
    : undefined;

  const [onlyOTC, setOnlyOTC] = useState<
    undefined | {value: string; label: string}
  >(initialOTC);

  const initialPaid = sessionStorage.getItem('paid')
    ? JSON.parse(sessionStorage.getItem('paid') || '')
    : undefined;

  const [paid, setPaid] = useState<undefined | {value: string; label: string}>(
    initialPaid
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setApiKeyWord(key);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [key]);

  useEffect(() => {
    sessionStorage.setItem('paid', paid ? JSON.stringify(paid) : '');
    sessionStorage.setItem('onlyOTC', onlyOTC ? JSON.stringify(onlyOTC) : '');
    sessionStorage.setItem(
      'preferenceTime',
      preferenceTime ? JSON.stringify(preferenceTime) : ''
    );
    sessionStorage.setItem('date', date ? JSON.stringify(date) : '');
    sessionStorage.setItem(
      'bill_date',
      billdate ? JSON.stringify(billdate) : ''
    );
    sessionStorage.setItem(
      'request_statuses',
      JSON.stringify(statuses.length > 0 ? statuses : [])
    );
    sessionStorage.setItem(
      'location',
      location ? JSON.stringify(location) : ''
    );
    sessionStorage.setItem('request_types', types ? JSON.stringify(types) : '');
  }, [
    paid,
    onlyOTC,
    preferenceTime,
    date,
    billdate,
    statuses,
    location,
    types,
  ]);

  const {data, refetch, isLoading} = useQuery(
    [
      'requests',
      String(filter.page || 0),
      order,
      status,
      filter.userId || '',
      keyWord,
      source,
      date,
      billdate,
      location,
      preferenceTime,
      onlyOTC,
      paid,
      statuses,
      types,
      pageSize,
    ],
    getRequests
  );

  const onChangeData = (val: any) => {
    setDate(val);
  };

  const onChangeLocation = (value: any) => {
    if (value) {
      setLocation((prev) =>
        prev.some((item) => item.label === value.label)
          ? reject(prev, value)
          : [...prev, value]
      );
    } else {
      setLocation([]);
    }
  };

  const onChangeStatuses = (value: any) => {
    if (value) {
      setStatuses((prev) =>
        prev.some((item) => item.label === value.label)
          ? reject(prev, value)
          : [...prev, value]
      );
    } else {
      setStatuses([]);
    }
  };
  const onChangeType = (value: any) => {
    setTypes(value);
  };

  const tabs = useMemo(function () {
    return [
      {title: 'All', value: ''},
      {title: 'New', value: 'new'},
      {title: 'In-Progress', value: 'in_progress'},
      {title: 'Closed', value: 'completed'},
      // {title: 'Invalid', value: 'invalid'},
      {title: 'Incomplete', value: 'incomplete'},
      // {title: 'Call Doctor', value: 'call_doctor'},
    ];
  }, []);

  const MMtabs = useMemo(function () {
    return [
      {title: 'All', value: ''},
      {title: 'Unbilled', value: 'completed'},
      {title: 'Billed', value: 'billed'},
      {title: 'Filled', value: 'filled'},
      {title: 'OTC CheckOut', value: 'otc_status'},
      // {title: 'Paid', value: 'paid'},
      {title: 'Waiting for Pickup', value: 'waiting_for_pickup'},
      {title: 'Ready for Delivery', value: 'ready_for_delivery'},
      {title: 'Delivery Partner', value: 'delivery_partner'},
      {title: 'Picked-Up', value: 'picked_up'},
      {title: 'Delivered', value: 'delivered'},
      {title: 'Canceled', value: 'cancel_script'},
      {title: 'Archived', value: 'archive'},
    ];
  }, []);

  return {
    refetch,
    data: data && !isLoading ? transformData(data.results, source) : [],
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    count: data?.count || 0,
    dailyCount: data?.dailyCount,
    loading: isLoading,
    sortBy,
    sortOrder,
    tabs,
    MMtabs,
    status,
    keyWord: key,
    setKeyWord,
    onSort,
    setStatus,
    source,
    // setSource,
    setDate,
    date,
    onChangeData,
    onChangeLocation,
    location,
    setPreferenceTime,
    preferenceTime,
    setOnlyOTC,
    onlyOTC,
    paid,
    setPaid,
    onChangeStatuses,
    statuses,
    visible,
    setVisible,
    setBillDate,
    billdate,
    onChangeType,
    types,
    pageSize,
    setPageSize,
    setLocation,
    setStatuses,
    setTypes,
  };
}

export default useRequests;
