// import api from 'api';
import api from 'api';
import ViewDetails from 'components/ViewDetails';
import {format} from 'date-fns';
import {BlogStatus} from 'pages/Blogs/BlogsList/useBlogs';
import {useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';
import {BlogResponse, ListResponse} from 'types';

async function getBlogs({queryKey}: QueryFunctionContext<string[]>) {
  const [, page, search, status, pageSize] = queryKey;
  const {data} = await api.get<ListResponse<BlogResponse>>('/support/blogs/', {
    params: {
      page,
      offset: +page * 20,
      limit: 20,
      search,
      status,
      content_type: 'page',
    },
  });

  return {
    data,
    results: data.results,
    count: 0,
    offset: +page * +pageSize,
    limit: +pageSize,
  };
}

const transformData = (data: BlogResponse[]) => {
  return data.map((item) => ({
    ...item,
    image: item.image ? (
      <img
        style={{width: 70, height: 40, objectFit: 'contain'}}
        alt={item.title}
        src={item.image.medium}
      />
    ) : null,
    active: <BlogStatus id={item.slug} active={item.is_active} />,
    created_at: format(new Date(item.created_at), 'MM/dd/yyyy hh:mm a'),
    view: <ViewDetails to={`/website/pages/${item.slug}`} />,
  }));
};

function useBlogs() {
  const [currentPage, setCurrentPage] = useState(parseInt('1'));
  const [pageSize, setPageSize] = useState(40);

  const {data, isLoading} = useQuery({
    queryKey: ['pages', String(currentPage - 1), String(pageSize)],
    queryFn: getBlogs,
  });

  return {
    data: data?.results ? transformData(data.results) : [],
    isLoading,
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    count: data?.count || 0,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  };
}

export default useBlogs;
