import {SingleValue, ActionMeta} from 'react-select';

import Typography from 'components/Typography';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import ScriptStatusSelect, {
  ScriptStatusOption,
} from 'components/ScriptStatusSelect';

import {Prescription, SelectOption} from 'types';

import styles from '../RequestDetails.module.scss';
import Button from 'components/Button';
import {useCallback, useEffect, useState} from 'react';
import PrescriptionDrugModal from './PrescriptionDrugModal';
import {RequestService} from 'services/request.service';
import {useQueryClient} from 'react-query';
import {get} from 'lodash';
import ArrowBottomIcon from './icons/ArrowBottomIcon';

interface Props {
  isPrescriber: boolean;
  showKits: boolean;
  requestId: number;
  origin?: string;
  status?: string;
  sync_source?: string | null;
  drugs: Prescription[];
  onSendNotification: () => Promise<any>;
  onCharge: () => Promise<any>;
  onRequestSignature: () => Promise<any>;
  onChangeStatus: (
    newValue: SingleValue<ScriptStatusOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => void;
  sync_time: string;
}

function Prescriptions({
  drugs,
  onCharge,
  onChangeStatus,
  onSendNotification,
  // onRequestSignature,
  requestId,
  origin,
  status,
  sync_source,
  isPrescriber,
  showKits,
  sync_time,
}: Props) {
  const [showPrescriptions, setShowPrescriptions] = useState(isPrescriber);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDrug, setSelectedDrug] = useState<Prescription>();
  const queryClient = useQueryClient();

  const handleEditDrug = useCallback((drug: Prescription) => {
    setSelectedDrug(drug);
    if (drug) {
      setIsEditModalOpen(true);
    }
  }, []);

  const handleUpdateDrug = useCallback(
    async (data: {amount_due?: string}) => {
      if (!selectedDrug) {
        return;
      }

      const requestService = new RequestService();
      await requestService.updateRequestDrug(selectedDrug.id, data);
      queryClient.invalidateQueries(['request_details', requestId.toString()]);
      setIsEditModalOpen(false);
    },
    [queryClient, requestId, selectedDrug]
  );

  useEffect(() => {
    if (selectedDrug) {
      setSelectedDrug(drugs.find((item) => item.id === selectedDrug.id));
    } else {
      if (drugs.length > 0) {
        setSelectedDrug(drugs[0]);
      }
    }
  }, [drugs, selectedDrug]);

  return (
    <Card>
      <PrescriptionDrugModal
        isOpen={isEditModalOpen}
        drug={selectedDrug}
        sync_source={sync_source}
        onClose={() => setIsEditModalOpen(false)}
        // onChangeStatus={onChangeStatus}
        onSubmit={handleUpdateDrug}
      />
      <CardContent px="sm" pt="md" pb="sm">
        <Typography component="p" variant="label">
          Prescriptions
        </Typography>
        {drugs.map((drug) => (
          <div
            key={drug.id}
            style={{
              borderBottomWidth: !origin?.startsWith('app') ? 1 : 0,
            }}
            className={styles.drug}
          >
            <div
              onClick={() =>
                get(drug, 'meds_kit') && get(drug, 'meds_kit', []).length > 0
                  ? setShowPrescriptions((pr) => !pr)
                  : null
              }
              style={{
                display: 'flex',
                width: '100%',
                cursor:
                  get(drug, 'meds_kit') && get(drug, 'meds_kit', []).length > 0
                    ? 'pointer'
                    : 'auto',
              }}
            >
              <div style={{width: '100%'}}>
                <Typography
                  component="p"
                  variant="h3"
                  className={styles.drug__name}
                >
                  {drug.name}
                </Typography>
                <Typography component="p" variant="label">
                  {get(drug, 'meds_kit')
                    ? get(drug, 'meds_kit', []).length
                    : ''}
                </Typography>
              </div>
              {get(drug, 'meds_kit') &&
                get(drug, 'meds_kit', []).length > 0 && (
                  <ArrowBottomIcon
                    style={{
                      transform:
                        showPrescriptions || showKits ? 'rotate(180deg)' : '',
                    }}
                  />
                )}
            </div>
            {(showPrescriptions || showKits) && (
              <div style={{marginBottom: 20}}>
                {get(drug, 'meds_kit', []).map((item) => {
                  return (
                    <Card key={get(item, 'id')}>
                      <CardContent pt="none" px="sm" pb="none">
                        <Typography component="p" variant="strong">
                          {get(item, 'medication')}
                        </Typography>
                        {/* <Typography variant="p">
                        {get(item, 'indication')}
                      </Typography> */}
                      </CardContent>
                    </Card>
                  );
                })}
              </div>
            )}

            {!isPrescriber && (
              <>
                <div className={styles.drugActions}>
                  <ScriptStatusSelect
                    name={`drug-${drug.id}`}
                    status={drug.status}
                    onChange={onChangeStatus}
                    type="prescription"
                    origin={origin}
                    isPvfr={true}
                    isVerified={!!selectedDrug?.is_pvrf_done}
                    // isPvfr={drugs.filter((item) => !item.is_pvrf_done).length === 0}
                    isDisabled={drug.status === 'delivered'}
                  />
                  <Button
                    color="outlined"
                    component="button"
                    size="sm"
                    onClick={() => handleEditDrug(drug)}
                  >
                    Edit
                  </Button>
                </div>
                <div className={styles.payments}>
                  <div className={styles.payment}>
                    <Typography
                      variant="label"
                      className={styles.payment__label}
                    >
                      Due
                    </Typography>
                    <Typography
                      variant="strong"
                      className={styles.payment__value}
                    >
                      ${drug.amount_due || 0}
                    </Typography>
                  </div>
                  <div className={styles.payment}>
                    <Typography
                      variant="label"
                      className={styles.payment__label}
                    >
                      Paid
                    </Typography>
                    <Typography
                      variant="strong"
                      className={styles.payment__value}
                    >
                      ${drug.paid || 0}
                    </Typography>
                  </div>
                </div>
              </>
            )}
            {!origin?.startsWith('app') && (
              <>
                <div style={{marginTop: 10}} className={styles.row}>
                  <p style={{margin: 0, padding: 0, fontSize: 14}}>RX Number</p>
                  <p style={{margin: 0, padding: 0, fontSize: 14}}>
                    <b>{get(drug, 'rx_no', '')}</b>
                  </p>
                </div>
                <div style={{marginTop: 10}} className={styles.row}>
                  <p style={{margin: 0, padding: 0, fontSize: 14}}>
                    Refill Number
                  </p>
                  <p style={{margin: 0, padding: 0, fontSize: 14}}>
                    <b>{get(drug, 'refill_no', '') || '-'}</b>
                  </p>
                </div>
                <div style={{marginTop: 10}} className={styles.row}>
                  <p style={{margin: 0, padding: 0, fontSize: 14}}>Bill Date</p>
                  <p style={{margin: 0, padding: 0, fontSize: 14}}>
                    <b>{get(drug, 'bill_time', '') || '-'}</b>
                  </p>
                </div>
                <div style={{marginTop: 10}} className={styles.row}>
                  <p style={{margin: 0, padding: 0, fontSize: 14}}>Sync Time</p>
                  <p style={{margin: 0, padding: 0, fontSize: 14}}>
                    <b>{sync_time || '-'}</b>
                  </p>
                </div>
              </>
            )}
          </div>
        ))}

        {!origin?.startsWith('app') && !isPrescriber && (
          <div className={styles.row}>
            <Button
              color="blue"
              disabled={
                drugs.filter((item) => Number(item.amount_due) > 0).length ===
                  0 ||
                (status === 'completed'
                  ? drugs.filter((item) => item.status === 'completed')
                      .length === 0
                  : status === 'billed'
                  ? drugs.filter((item) => item.status === 'billed').length ===
                    0
                  : drugs.filter((item) => item.status === 'filled').length !==
                    0
                  ? false
                  : true) ||
                drugs.filter((item) => !item.is_pvrf_done).length !== 0
              }
              onClick={onSendNotification}
              className={styles.notify_action}
            >
              Notify to Pay
            </Button>
            <Button
              color="blue"
              onClick={onCharge}
              disabled={
                get(drugs, '[0].is_paid', false) ||
                Number(get(drugs, '[0].amount_due', 0)) === 0 ||
                selectedDrug?.status !== 'filled'
              }
              className={styles.notify_action}
            >
              Charge Card
            </Button>
          </div>
        )}
        {/* <Button
          color="blue"
          disabled={
            drugs.filter((item) => item.status === 'ready_for_delivery')
              .length === 0
          }
          onClick={onRequestSignature}
          className={styles.notify_action}
        >
          Request for signature
        </Button> */}
      </CardContent>
    </Card>
  );
}

export default Prescriptions;
