import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import FormActionGroup from 'components/FormActionGroup';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import ListWrapper from 'components/ListWrapper';
import CardContent from 'components/CardContent';
// import {validateEmail} from 'utils/validators';

import useEmployeeForm from './useEmployeeForm';

import styles from './EmployeeForm.module.scss';
import {useCallback, useState} from 'react';
import useDeleteEmployee from './useDeleteEmployee';
import {useHistory} from 'react-router-dom';
import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import {formatDateString} from 'pages/Settings/Users/Users';
import DataTable from 'components/DataTable';
import {DataTableSchemaItem} from 'types';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'checkbox',
    header: 'Checkbox',
  },
  {
    dataKey: 'type',
    header: 'Type',
  },
  {
    dataKey: 'medication',
    header: 'Medication',
  },

  {
    dataKey: 'quantity',
    header: 'Quantity',
    align: 'center',
  },
  {
    dataKey: 'directions',
    header: 'Directions',
  },
  {
    dataKey: 'indication',
    header: 'Indication',
  },
];

function EmployeeForm() {
  const {
    employeeId,
    submitting,
    errors,
    register,
    handleSubmit,
    setValue,
    medications,
    isLoading,
    contentRef,
  } = useEmployeeForm();
  const history = useHistory();
  const {deleteEmployee, isDeleting} = useDeleteEmployee();
  const [birthdate, setBirthdate] = useState('');

  const handleDeleteEmployee = useCallback(
    async (id: number) => {
      await deleteEmployee(id);

      history.replace('/prescriber/prescriptions');
    },
    [deleteEmployee, history]
  );

  function handleDateChange(event: {target: {value: any}}) {
    const inputDate = event.target.value;
    const formattedDate = formatDateString(inputDate);
    setBirthdate(formattedDate);
    setValue('birthday', formattedDate);
  }

  return (
    <form onSubmit={handleSubmit}>
      <PageHeader>
        <Typography variant="h2">
          {employeeId ? 'Edit Prescriber' : 'New Prescription'}
        </Typography>
        <FormActionGroup>
          {!!employeeId && (
            <Button
              onClick={() => handleDeleteEmployee(+employeeId)}
              color="danger"
              loading={isDeleting}
            >
              Delete
            </Button>
          )}
          {/* <Button to="/prescriber/prescriptions" component="a" color="gray">
            Cancel
          </Button> */}
        </FormActionGroup>
      </PageHeader>
      <div ref={contentRef}>
        <Typography
          style={{marginTop: 10, fontSize: 16, lineHeight: 1.6}}
          variant="p"
        >
          The Meds Pack is a preventive care toolkit tailored for travelers,
          providing peace of mind and potentially life-saving support. It
          ensures doctors can recommend a reliable, pre-prepared medical
          solution for patients wary of using unfamiliar local medicines while
          traveling, particularly on extended trips.
        </Typography>
        <ListWrapper>
          <CardContent px="md" pt="none" pb="md">
            <Typography className={styles.header} variant="h3">
              Patient details
            </Typography>
            <FormGroup columns={2}>
              <div className={styles.patient_details_container}>
                <FormGroup columns={2}>
                  <TextField
                    label="First Name"
                    placeholder="First Name"
                    {...register('first_name', {required: 'Required'})}
                    error={errors.first_name?.message}
                  />
                  <TextField
                    label="Last Name"
                    placeholder="Last Name"
                    {...register('last_name', {required: 'Required'})}
                    error={errors.last_name?.message}
                  />
                </FormGroup>

                <FormGroup columns={2}>
                  <TextField
                    label="Email Address"
                    placeholder="Email Address"
                    // {...register('email', {
                    //   required: 'Required',
                    //   validate: validateEmail,
                    // })}
                    error={errors.email?.message}
                  />
                  <TextField
                    label="Phone Number"
                    placeholder="(123) 456-7890"
                    {...register('phone', {
                      required: 'Required',
                      minLength: {
                        value: 14,
                        message: 'Enter a valid phone number',
                      },
                    })}
                    onChange={(e) => {
                      let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
                      let formatted = '';

                      if (input.length > 0)
                        formatted += '(' + input.substring(0, 3);
                      if (input.length >= 4)
                        formatted += ') ' + input.substring(3, 6);
                      if (input.length >= 7)
                        formatted += '-' + input.substring(6, 10);

                      e.target.value = formatted;
                    }}
                    error={errors.phone?.message}
                  />
                </FormGroup>
                <FormGroup columns={2}>
                  <TextField
                    label="Date of Birth"
                    placeholder="mm/dd/yyyy"
                    {...register('birthday', {
                      required: 'Required',
                      minLength: {
                        value: 10,
                        message: 'Invalid date',
                      },
                    })}
                    value={birthdate}
                    onChange={handleDateChange}
                    maxLength={10}
                    error={errors.birthday?.message}
                  />
                  <GooglePlaceAutoComplete
                    label="Address"
                    placeholder="Address"
                    {...register('address_line_1', {
                      required: 'Required',
                    })}
                    onSelectPlace={(data) => {
                      setValue('address_line_1', data.addressLine1);
                      setValue('city', data.city);
                      setValue('zipcode', data.zipcode);
                      setValue('state', data.state);
                      setValue('longitude', data.lon);
                      setValue('latitude', data.lat);
                    }}
                    error={errors.address_line_1?.message}
                    options={{
                      types: ['address'],
                    }}
                  />
                </FormGroup>
                <p>Allergies</p>
                <textarea
                  className={styles.noteInput}
                  {...register('allergies', {
                    required: 'Required',
                  })}
                />
              </div>
              <div className={styles.meds_kit_container}>
                <img
                  className={styles.meds_kit}
                  src={require('./meds_kit.png')}
                  alt="meds_kit"
                />
              </div>
            </FormGroup>
          </CardContent>
        </ListWrapper>
        <Typography style={{marginTop: 20, fontSize: 16}} variant="p">
          *Meds Kit
        </Typography>
        <Typography
          style={{marginTop: 10, fontSize: 16, lineHeight: 1.6}}
          variant="p"
        >
          The Kit includes the following medications listed below. If the
          patient is allergic or should not be prescribed any medications listed
          below, please uncheck them in order to exclude them from the kit.
        </Typography>
        <ListWrapper>
          <CardContent px="sm" pt="none" pb="md">
            <Typography
              style={{marginLeft: 25, marginBottom: 20}}
              className={styles.header}
              variant="h3"
            >
              Medications
            </Typography>
            <DataTable data={medications} schema={SCHEMA} loading={isLoading} />
          </CardContent>
        </ListWrapper>
        <Button
          style={{marginLeft: 'auto', marginTop: 20, marginBottom: 50}}
          type="submit"
          color="green"
          loading={submitting}
        >
          Send Prescription
        </Button>
      </div>
    </form>
  );
}

export default EmployeeForm;
